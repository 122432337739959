import React from "react";
import "./Projects.css";
import ProjectCard from "../ProjectCard/ProjectCard";

const Projects = () => {
  return (
    <div
      id="portfolio"
      className="black-background portfolioContainer padding-sites"
    >
      <h2 className="white headingProjects">My Work</h2>
      <ProjectCard
        projectImg="/youtubeClone.png"
        reverse={false}
        description="MyTube ermöglicht Benutzern das Durchsuchen und Anzeigen von Videos. Mit Hilfe von React und der YouTube-API können Benutzer auf eine große Auswahl an Videos zugreifen."
        linkToProject="https://niki21599.github.io/youtube_clone/"
        linkToGithub="https://github.com/niki21599/youtube_clone"
        skills="React | Material UI | REST-API"
        projectName="MyTube"
      />
      <ProjectCard
        projectImg="/adminDashboard.png"
        reverse={true}
        description="MyAdmin ist ein Management-System zur Verwaltung und Visualisierung aller unternehmensinternen Daten. Die dargestellten Daten sind keine echten Daten, sondern Beispieldaten.  "
        linkToProject="https://niki21599.github.io/my_admin/"
        linkToGithub="https://github.com/niki21599/my_admin"
        skills="React  | Material UI  "
        projectName="MyAdmin"
      />
      <ProjectCard
        projectImg="/kanbanScreenshot.png"
        reverse={false}
        description="MyKanban ist ein Kanban Board mit React Frontend und Django Backend, das eine effiziente Aufgabenverwaltung ermöglicht. Aufgaben können mit Drag-and-Drop-Funktionen einfach verschoben und organisiert werden."
        linkToProject=""
        linkToGithub="https://github.com/niki21599/kanban-frontend"
        skills="React | Redux | Material UI | Django"
        projectName="MyKanban"
        disabled={"disabled"}
      />
    </div>
  );
};

export default Projects;
